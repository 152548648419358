.main_footer {
  min-height: 110px;
  display: flex;
  align-items: left;
  flex-direction: column;
  background: url(../../images/chicken.svg) no-repeat bottom -157px left 85%;
  background-color: #FFF;
  margin-top: auto;
  opacity: 0;
  padding: 0 40px 22px;
  animation-name: footerView;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.17,.67,.34,1.27);
  will-change: opacity;
}

@keyframes footerView {
  to {
    opacity: 1;
  }
}

.main_footer__view {
  display: flex;
}

.main_footer__copyright {
  display: block;
  font-size: 18px;
  line-height: 1.55;
  margin-top: 132px;
}

.main-footer__title-contact {
  margin-bottom: 40px;
  font-size: 96px;
  line-height: 125px;
  font-weight: normal;
  margin-bottom: 56px;
}

.main-footer__link-contact {
  display: inline-block;
  font-size: 80px;
  line-height: 104px;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 27px 40px 27px 130px;
  background: url(../../images/envelope.svg) no-repeat left 40px center;
  background-color: #F4F6FF;
  background-size: 60px;
  border-radius: 40px;
  width: fit-content;
}

.main-footer__link-contact_telegram {
  padding: 0 24px;
  background-image: none;
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  min-height: 142px;
}

.main-footer__link-contact_telegram__photo_wrapper {
  display: flex;
}

.main-footer__link-contact_telegram__photo {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  margin-right: 16px;
}

.main-footer__link-contact_telegram__description {
  margin-bottom: 22px;
}

.main-footer__link-contact_telegram__url,
.main-footer__link-contact_telegram__work-post {
  display: block;
}

.main-footer__link-contact_telegram__url {
  line-height: 95px;
}

.main-footer__link-contact_telegram__work-post {
  font-size: 13px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 1640px) {
  .main-footer__link-contact {
    font-size: 64px;
    line-height: 83px;
  }
}

@media (max-width: 1440px) {
  .main-footer__link-contact_telegram__photo {
    width: 80px;
    height: 80px;
  }

  .main-footer__link-contact {
    padding: 20px 40px 20px 112px;
    font-size: 52px;
    line-height: 70px;
  }

  .main-footer__link-contact_telegram__url {
    line-height: 70px;
  }

  .main-footer__link-contact_telegram {
    padding: 0 24px;
    min-height: 123px;
  }
}

@media (max-width: 1280px) {
  .main-footer__link-contact {
    font-size: 42px;
    line-height: 70px;
  }
}

@media (max-width: 990px) {
  .main_footer {
    background: none;
    padding: 0 8px 15px;
  }

  .main-footer__title-contact {
    font-size: 38px;
    line-height: 49px;
    margin-bottom: 16px;
  }

  .main-footer__link-contact {
    padding: 17px 19px 17px 64px;
    font-size: 16px;
    line-height: 30px;
    border-radius: 16px;
    background-size: 32px;
    background-position: left 17px center;
  }

  .main-footer__link-contact_telegram__url {
    line-height: 30px;
  }

  .main-footer__link-contact_telegram {
    padding: 8px 16px 8px 8px;
    font-size: 20px;
    line-height: 30px;
    min-height: 100%;
  }

  .main-footer__link-contact_telegram__photo_wrapper {
    display: flex;
  }

  .main-footer__link-contact_telegram__photo {
    width: 56px;
    height: 56px;
    border-radius: 12px;
  }

  .main-footer__link-contact_telegram__description {
    margin-bottom: 0;
  }

  .main-footer__link-contact_telegram__url {
    text-transform: uppercase;
  }

  .main-footer__link-contact_telegram__work-post {
    font-size: 12px;
    line-height: 17px;
    margin-top: 0px;
  }
  
  .main_footer__copyright {
    font-size: 20px;
    line-height: 26px;
    margin-top: 40px;
  }
}

@media (max-width: 374px) {
  .main-footer__link-contact {
    font-size: 12px;
  }

  .main-footer__link-contact_telegram {
    font-size: 16px;
  }

  .main-footer__link-contact_telegram__work-post {
    font-size: 10px;
    line-height: 17px;
    margin-top: 0px;
  }
}