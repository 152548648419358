.tags-list {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 890;
  animation-fill-mode: forwards;
  opacity: 0;
  animation-name: tagsList;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(.17,.67,.34,1.27);
  animation-fill-mode: forwards;
}

@keyframes tagsList {
  to {
    opacity: 1;
  }
}

.tags-list__item {
  margin-right: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  height: 46px;
  font-size: 20px;
  line-height: 30px;
  border-radius: 16px;
  box-shadow: 0px 6px 26px rgba(183, 200, 213, 0.3);
  background-color: #FFF;
  transition: .3s linear;
  transition-property: font-size;
}

.tags-list__item:active {
  box-shadow: 0px 8px 30px rgba(183, 200, 213, 0.5);
}

.tags-list__item__radio:checked + .tags-list__item__tag {
  box-shadow: 0px 10px 30px rgba(183, 200, 213, 0.4);
}


.tags-list__item.selected:nth-child(8n + 1) {
  background-color: #E8FCFF;
  color: #53BADA;
}

.tags-list__item.selected:nth-child(8n + 2) {
  background-color: #EAF1FF;
  color: #7894F9;
}

.tags-list__item.selected:nth-child(8n + 3) {
  background-color: #FEECF7;
  color: #ECA9D0;
}

.tags-list__item.selected:nth-child(8n + 4) {
  background-color: #F1E2FF;
  color: #A889FF;
}

.tags-list__item.selected:nth-child(8n + 5) {
  background-color: #EAFFF0;
  color: #70937A;
}

.tags-list__item.selected:nth-child(8n + 6) {
  background-color: #F6FFD3;
  color: #A69F5A;
}

.tags-list__item.selected:nth-child(8n + 7) {
  background-color: #FEE6E6;
  color: #A76C6C;
}

.tags-list__item.selected:nth-child(8n + 8) {
  background-color: #FFE8D3;
  color: #967E69;
}

@media (hover:hover) {
  .tags-list__item:hover {
    box-shadow: 0px 10px 30px rgba(183, 200, 213, 0.4);
  }
  
  .tags-list__item:nth-child(8n + 1):hover {
    background-color: #E8FCFF;
    color: #53BADA;
  }
  .tags-list__item:nth-child(8n + 2):hover {
    background-color: #EAF1FF;
    color: #7894F9;
  }
  
  .tags-list__item:nth-child(8n + 3):hover {
    background-color: #FEECF7;
    color: #ECA9D0;
  }
  
  .tags-list__item:nth-child(8n + 4):hover {
    background-color: #F1E2FF;
    color: #A889FF;
  }
  
  .tags-list__item:nth-child(8n + 5):hover {
    background-color: #EAFFF0;
    color: #70937A;
  }
  
  .tags-list__item:nth-child(8n + 6):hover {
    background-color: #F6FFD3;
    color: #A69F5A;
  }
  
  .tags-list__item:nth-child(8n + 7):hover {
    background-color: #FEE6E6;
    color: #A76C6C;
  }
  
  .tags-list__item:nth-child(8n + 8):hover {
    background-color: #FFE8D3;
    color: #967E69;
  }
}

@media (max-width: 1440px) {
  .tags-list {
    top: 90px;
  }
}

@media (max-width: 1024px) {
  .tags-list {
    position: static;
    animation: none;
    opacity: 1;
  }
}

@media (max-width: 992px) {
  .tags-list__item {
    padding: 8px 16px;
    font-size: 20px;
    border-radius: 18px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .tags-list__item.scrolled {
    transform: scale(1);
    margin-right: 8px;
  }
}
