.other-project {
  width: 100%;
  margin-bottom: 64px;
}

.other-project__title {
  font-size: 96px;
  line-height: 125px;
  margin-bottom: 56px;
  font-weight: normal;
}

@media (max-width: 992px) {
  .other-project {
    margin-bottom: 26px;
  }
  
  .other-project__title {
    font-size: 38px;
    line-height: 49px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
}
