.page-project {
  box-sizing: border-box;
  padding: 80px 40px 0;
  position: relative;
  overflow: hidden;
}

.page-project__pig-image {
  position: absolute;
  top: 70px;
  right: -200px;
  max-height: 250px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.page-project__pig-image:hover {
  transform: rotate(-15deg);
}

.pig-image-reset-animation {
  animation: resetPigFly 1.5s ease-in-out;
  animation-fill-mode: forwards;
}

.pig-image-fly {
  animation: pigFlyAnimation 2.0s ease-in-out;
  animation-fill-mode: forwards;
}

.page-project__title {
  font-size: 96px;
  line-height: 125px;
  margin-bottom: 8px;
  font-weight: normal;
}

.page-project__description {
  margin-bottom: 24px;
  max-width: 880px;
  font-weight: 300;
  font-size: 35px;
  line-height: 46px;
  margin-top: 0;
}

.page-project__tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  margin-bottom: 8px;
}

.page-project__tag {
  font-size: 16px;
  line-height: 32px;
  color: #828282;
  box-sizing: border-box;
  padding: 0 16px;
  background-color: #F8FAFB;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: baseline;
  border-radius: 8px;
  margin: 0 4px 16px;
}

.page-project__link {
  display: block;
  width:fit-content;
  padding: 0 16px 0 42px;
  background: url(../images/linkIcon.svg) no-repeat left 16px center;
  background-size: 18px;
  background-color: #E9ECFF;
  color: #6C96FF;
}

.page-project__store-link {
  background-color: #F1E6FF;
  color: #8D6BEC;
}

.page-project__main-image_wrapper {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 80px;
  border-radius: 25px;
  overflow: hidden;
}

.page-project__video_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 56.25%;
  margin: 0 280px;
}

.page-project__react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.page-project__main-image__tavi-gif {
  object-fit: contain;
  width: calc(100vw - 10%);
  max-width: 498px;
  height: auto;
}

.page-project__main-image__tavi-gif_wrapper {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-height: 18%;
}

@media (max-width: 1440px) {

  .page-project__video_wrapper {
    margin: 0 140px;
  }
}

@media (max-width: 1000px) {
  .page-project__pig-image {
    display: none;
  }
}

@media (max-width: 992px) {
  .page-project {
    padding: 86px 8px 0;
  }

  .page-project__link {

  }

  .page-project__title {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .page-project__description {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
    font-weight: normal;
  }

  .page-project__main-image_wrapper {
    margin-bottom: 40px;
    min-width: 100%;
  }

  .page-project__video_wrapper {
    margin: 24px 24px 0;
    visibility: hidden;
    opacity: 0;
    transition: .3s ease-in;
    transform: translateY(-40px);
    border-radius: 12px;
    background-color: #000;
    outline: none;
    position: absolute;
  }

  .page-project__video_wrapper.play {
    display: block;
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }

  .page-project__video__play-button {
    width: 160px;
    height: 160px;
    background: url('../images/play_icon.svg') no-repeat;
    background-size: contain;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(24px);
    border-radius: 50%;
    position: absolute;
    top: 103px;
    left: 0;
    right: 0;
    margin: 0 auto;
    cursor: pointer;
    border: none;
    visibility: hidden;
    opacity: 0;
    transition: .3s linear;
    transform: scaleY(0.6);
  }

  .page-project__main-image__tavi-gif_wrapper {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    max-height: 498px;
  }

  @media (max-width: 640px) {
    .page-project__link {

    }
  }
}

@keyframes pigFlyAnimation {
  from {
    right: -130px;
    opacity: 1;
    height: 250px;
  } to {
    right: 1500px;
    opacity: 0;
    height: 0px;
  }
}

@keyframes resetPigFly {
  from {
    right: -200px;
  } to {
    right: -10px;
  }
}
