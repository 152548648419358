.working-group {
  margin-bottom: 72px;
}

.working-group__title {
  font-size: 96px;
  line-height: 125px;
  font-weight: normal;
  margin-bottom: 56px;
  animation: opacityAnimation 3s cubic-bezier(.17,.67,.34,1.27);
  animation-fill-mode: forwards;
  opacity: 0;
}

.working-group__title.animation {
  opacity: 1;
}

.working-group__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.working-group__list__item {
  margin: 0 8px 16px;
  width: calc((100% / 6) - 16px);
  animation: opacityAnimation 3s cubic-bezier(.17,.67,.34,1.27);
  animation-fill-mode: forwards;
  opacity: 0;
}

.working-group__list__item:nth-child(1) {
  animation-delay: .1s;
}

.working-group__list__item:nth-child(2) {
  animation-delay: .2s;
}

.working-group__list__item:nth-child(3) {
  animation-delay: .3s;
}

.working-group__list__item:nth-child(4) {
  animation-delay: .4s;
}

.working-group__list__item:nth-child(5) {
  animation-delay: .5s;
}

.working-group__list__item:nth-child(6) {
  animation-delay: .6s;
}

@keyframes opacityAnimation {
  to {
    opacity: 1;
  }
}

.working-group__list__item__photo-wrap {
  display: flex;
  border-radius: 56px;
  margin-bottom: 20px;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
}

.working-group__list__item__photo {
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: opacity .3s linear;
  align-self: baseline;
}

.working-group__list__item__name {
  display: block;
  margin-bottom: 13px;
  font-size: 20px;
  line-height: 26px;
  color: #000;  
}

.working-group__list__working-position {
  list-style: none;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  line-height: 30px;
}

@media (max-width: 1440px) {
  .working-group__list__working-position {
    font-size: 20px;
    line-height: 30px;
  }

  .working-group {
    margin-bottom: 56px;
  }
}

@media (max-width: 1279px) {
  .working-group__list__working-position {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 1200px) {
  .working-group__list__item {
    width: calc((100% / 5) - 16px);
  }
}

@media (max-width: 1020px) {
  .working-group__list__item {
    width: calc((100% / 4) - 16px);
  }
}

@media (max-width: 992px) {
  .working-group {
    margin-bottom: 48px;
  }

  .working-group__list {
    margin: 0;
  }

  .working-group__title {
    margin-bottom: 24px;
    font-size: 38px;
    line-height: 49px;
    animation: none;
    opacity: 1;
  }

  .working-group__list__item__photo-wrap {
    border-radius: 24px;
  }

  .working-group__list__item {
    animation: none;
    opacity: 1;
  }
}

@media (max-width: 820px) {
  .working-group__list__item {
    max-width: calc((100% / 3) - 16px);
  }
}

@media (max-width: 650px) {
  .working-group__list {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .working-group__list__item {
    max-width: 100%;
    width: 100%;
    min-width: auto;
  }

  .working-group__list__item__photo-wrap {
    margin-bottom: 0;
    margin-right: 16px;

  }

  .working-group__list__item__photo {
    width: 172px;
  }

  .working-group__list__working-position {
    font-size: 16px;
    line-height: 24px;
  }

  .working-group__list__item {
    display: flex;
    align-items: center;
  }
}