.container {
  display: flex;
  flex-direction: column;
  padding: 170px 180px;
  border-radius: 24px;
  background: #1C1C23;
}

.firstContainer {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  align-items: center;
}

.logo {

}

.firstContainer__handImageContainer {
  box-sizing: border-box;
  overflow: hidden;
  max-height: 800px;
}

.firstContainer__handImageContainer__image {
  width: 100%;
  height: 100%;
}

.firstContainer__handImageContainer__image2 {
  display: none;
}

.firstContainer__content__mobileImageContainer {
  display: none;
}

.firstContainer__content {
  margin-bottom: 150px;
  width: 400px;
}

.firstContainer__content__title {
  font-size: 28px;
  margin-top: 40px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: #E0DBC3;
}

.firstContainer__content__text {
  font-size: 20px;
  color: #A09684;
}

.firstContainer__content__buttons {
  display: flex;
  gap: 8px;
}

.reverseButtons {
  flex-direction: column;
}

.firstContainer__content__appstoreButton {
  cursor: pointer;
  border-radius: 12px;
  background: #FFAD17;
  border: none;
  padding: 16px 18px;
  height: 61px;
  box-sizing: border-box;
}

.firstContainer__content__button {
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  background: #544724;
  border: none;
  border-radius: 12px;
  height: 61px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 16px 18px;
  color: #FFAD17;
}

.secondContainer {
  display: flex;
  align-items: center;
}

.secondContainer__handImageContainer__imageMobile {
  display: none;
}

.secondContainer__handImageContainer__imageMobileSmall {
  display: none;
}

.secondContainer__content {
  width: 400px;
}

.secondContainer__handImageContainer {
  box-sizing: border-box;
  overflow: hidden;
  max-height: 770px;
}

.screens {
  overflow: hidden;
  margin-top: 160px;
}

.screens_screensImageMobile {
  display: none;
}

.screens_screensImageMobileSmall {
  display: none;
}

.thirdContainer {
  display: flex;
  margin: 140px 0;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
}

.thirdContainer__imageMobile {
  display: none;
}

.thirdContainer__content {
  width: 400px;
}

.thirdContainer__themesImageContainer {
  box-sizing: border-box;
  overflow: hidden;
  max-height: 880px;
}

.bottomContainer {
  display: flex;
  gap: 50px;
  align-items: center;
  margin: auto;
}

.bottomContainer__imageContainer {
  box-sizing: border-box;
  overflow: hidden;
  max-height: 270px;
}

.bottomContainer__content {
  width: 400px;
}

.bottomContainerMobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .container {
    padding: 24px;
  }
}

@media screen and (max-width: 667px) {
  .container {
    padding: 24px 0;
  }

  .firstContainer, .thirdContainer, .bottomContainer {
    gap: unset;
  }

  .firstContainer__content, .secondContainer__content, .thirdContainer__content, .bottomContainer__content {
    width: 305px;
  }

  .firstContainer {
    flex-direction: row-reverse;
    padding-left: 24px;
  }

  .firstContainer__handImageContainer__image {
    display: none;
  }

  .firstContainer__handImageContainer__image2 {
    display: block;
    width: 100%;
    height: 100%;
  }

  .secondContainer {
    flex-direction: row-reverse;
    padding-right: 24px;
  }

  .secondContainer__handImageContainer {
    padding-right: 20px;
  }

  .secondContainer__handImageContainer__imageMobile {
    display: block;
    width: 100%;
    height: 100%;
  }

  .screens {
    margin-top: 90px;
  }

  .screens_screensImageMobile {
    display: block;
  }

  .thirdContainer {
    padding-left: 24px;
    margin: 60px 0;
  }

  .thirdContainer__themesImageContainer {

  }

  .thirdContainer__image {
    display: block;
    width: 400px;
    height: 400px;
    object-position: left 40px bottom 0px;
    object-fit: contain;
  }

  .bottomContainer {
    margin: unset;
    justify-content: space-between;
    padding: 0 24px;
  }

  .bottomContainer__imageContainer {
    max-width: 250px;
  }

  .bottomContainer__image {
    display: block;
  }

}

@media screen and (max-width: 440px) {
  .container {
    padding: 0;
  }

  .firstContainer {
    flex-direction: column;
    padding: 0;
    margin-top: 24px;
  }

  .firstContainer__content, .secondContainer__content, .thirdContainer__content, .bottomContainer__content {
    width: 100%;
  }

  .firstContainer__handImageContainer__image2 {
    display: none;
  }

  .firstContainer__content__mobileImageContainer {
    display: block;
  }

  .logo {
    margin-left: 42px;
    margin-top: 40px;
  }

  .firstContainer__content__mobileImage {
    display: block;
    width: 100%;
    height: 100%;
    /* width: 400px;
    height: 400px; */
    /* object-fit: cover;
    object-position: left -40px bottom 30px; */
  }

  .firstContainer__content__title {
    margin: 0 24px 24px 24px;
  }

  .firstContainer__content__text {
    margin: 0 24px 24px 24px;
  }

  .firstContainer__content__buttons {
    margin: 24px;
    gap: 16px;
  }

  .firstContainer__content__button {
    width: 100%;
  }

  .secondContainer {
    flex-direction: column-reverse;
    padding: 0;
  }

  .secondContainer__content {
    padding: 24px;
  }

  .secondContainer__handImageContainer {
    width: 100%;
    height: 100%;
    padding: 0;
    margin-bottom: 60px;
  }

  .secondContainer__handImageContainer__imageMobile {
    display: none;
  }

  .secondContainer__handImageContainer__imageMobileSmall {
    display: block;
    width: 100%;
    height: 100%;
  }

  .screens {
    display: flex;
    margin: auto;
  }

  .screens_screensImageMobile {
    display: none;
  }

  .screens_screensImageMobileSmall {
    display: block;
  }

  .thirdContainer {
    padding: 0;
    flex-direction: column;
  }

  .thirdContainer__image {
    display: none;
  }

  .thirdContainer__themesImageContainer {
    width: 100%;
    height: 100%;
  }

  .thirdContainer__imageMobile {
    display: block;
    width: 100%;
    height: 100%;
  }

  .bottomContainer {
    display: none;
  }

  .bottomContainerMobile {
    display: flex;
    flex-direction: column;
  }

  .bottomContainerMobile .firstContainer__content__title {
    margin-bottom: 0;
  }

  .bottomContainerMobile__content {
    display: flex;
    justify-content: space-between;
  }

  .bottomContainerMobile__content__image {
    width: 190px;
    height: 190px;
    object-position: left 10px bottom 0px;
  }
}
