@import './fonts.css';

body {
  margin: 0;
  font-family:  'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;
  font-size: 16px;
  line-height: 1.25;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  margin: 0;
  color: #000;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul {
  margin-top: 0;
  margin-bottom: 0;
}

button {
  cursor: pointer;
}

button:focus, input:focus {
  outline: none;
}

.content-container {
  padding: 55px 40px 16px;
}

.pig-reset-animation {
  animation: resetPigFly 1.5s ease-in-out;
  animation-fill-mode: forwards;
}

.pig-fly-animation {
  animation: pigFlyAnimation 2.0s ease-in-out;
  animation-fill-mode: forwards;
}

@media (max-width: 992px) {
  .content-container {
    padding: 16px 8px;
  }
}

@keyframes pigFlyAnimation {
  from {
    right: -130px;
    opacity: 1;
    height: 250px;
  } to {
    right: 1500px;
    opacity: 0;
    height: 0px;
  }
}

@keyframes resetPigFly {
  from {
    right: -200px;
  } to {
    right: -10px;
  }
}
