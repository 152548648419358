.field-entity__image-container {
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(177, 177, 177, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-color: rgba(190, 190, 190, 0.2);
  background-size: 400% 100%;
  animation-name: imageLoad;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: normal;
}

@-webkit-keyframes imageLoad {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes imageLoad {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.field-entity__image-container__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity .6s ease;
  visibility: hidden;
}

.field-entity__image-container__img_showed {
  opacity: 1;
  visibility: visible;
}

.button-scroll-up {
  width: 120px;
  height: 120px;
  background: url(../images/arrow.svg) no-repeat center;
  background-color: rgba(251, 251, 251, 0.5);
  backdrop-filter: blur(50px);
  border: none;
  border-radius: 24px;
  position: fixed;
  bottom: 40px;
  right: 80px;
  z-index: 900;
  visibility: hidden;
  transition: .3s linear;
  transform: translateY(40px);
  opacity: 0;
}

.button-scroll-up.show {
  visibility: visible;
  transform: translateY(0px);
  opacity: 1;
}

.button-scroll-up:focus {
  outline: none;
}

@-moz-document url-prefix() {
  .button-scroll-up {
    background-color: rgba(251, 251, 251);
  }
}

.fixed-drop-message {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px 0;
  animation-name: showMessage;
  animation-duration: 5s;
  animation-timing-function: cubic-bezier(1,0,0,.99);
  transform: translateY(-100%);
}

.fixed-drop-message__card {
  margin: 0 auto;
  width: fit-content;
  align-self: baseline;
  font-size: 18px;
  line-height: 32px;
  background-color: #6df290;
  color: rgb(67, 67, 67);
  border-radius: 8px;
  padding: 0 16px;
  box-shadow: 0px 8px 30px rgba(37, 255, 146, 0.5);
}

@keyframes showMessage {
  15% {
    transform: translateY(0%);
  }
  85% {
    /* transform: translateY(-100%); */
    /* opacity: 0; */
  }
  100% {
    transform: translateY(-100%);
  }
}

@media (max-width: 992px) {
  .button-scroll-up {
    width: 84px;
    height: 84px;
    background: url(../images/small_arrow.svg) no-repeat center;
    border-radius: 16px;
    right: 24px;
  }
}