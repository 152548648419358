.dropdown-contact_container {
  width: 100%;
  background-color: transparent;
  height: 0;
  transition: .2s ease-in-out;
}

.dropdown-contact_container.show {
  height: 100vh;
  background-color: #FBFBFB;
}

.dropdown-contact {
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  transition: .2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  box-sizing: border-box;
  padding: 62px 8px 30px;
  transform: translateY(-20px);
}

.show .dropdown-contact {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  transition: .2s ease-in-out;
}

.notification {
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  transition: .2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  box-sizing: border-box;
  padding: 40px 8px;
  transform: translateY(-20px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.show .notification {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  transition: .2s ease-in-out;
}

.notification__img {
  width: 186px;
  height: 176px;
  margin-bottom: -20px;
}

.notification__title {
  font-size: 64px;
  line-height: 83px;
  font-weight: normal;
  margin-bottom: 16px;
}

.notification__description {
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 80px;
  max-width: 235px;
}

.notification__button {
  border: none;
  font-size: 20px;
  line-height: 26px;
  box-sizing: border-box;
  padding: 14px 16px;
  border-radius: 19px;
  min-height: 56px;
  display: flex;
  background-color: #E9ECFF;
  color: #6C96FF;
}

.notification__button::before {
  content: '';
  display: block;
  background: url('../images/calmSmile.svg') no-repeat center;
  background-size: contain;
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.notification__button:hover {
  background-color: #F4F6FF;
}

.notification__button:active {
  background-color: #E2E6FF;
}

.dropdown-contact__title_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.dropdown-contact__title {
  font-size: 64px;
  line-height: 83px;
  font-weight: normal;
}

.dropdown-contact__title_first {
  display: inline-flex;
  align-items: center;
}

.dropdown-contact__teamlid {
  display: inline-flex;
  align-items: center;
  margin: 0 16px;
}

.dropdown-contact__teamlid__info {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  width: 113px;
  margin-left: 16px;
}

.dropdown-contact__teamlid__info_mobile {
  display: none;
}

.dropdown-contact__teamlid__photo {
  max-width: 120px;
  object-fit: contain;
  border-radius: 24px;
}

.dropdown-contact__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1008px;
  margin: 0 auto;
}

.dropdown-contact__input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #9C9C9C;
  box-sizing: border-box;
  padding: 0 0 12px;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 40px;
  max-width: 600px;
  width: 100%;
  color: #000;
  transition: .3s ease-in;
  background-color: transparent;
}

.dropdown-contact__input:focus {
  border-bottom-color: #000;
}

.dropdown-contact__input_completed {
  border-bottom-color: #000;
}

.dropdown-contact__input::placeholder {
  color: #D7D7D7;
}

.dropdown-contact__buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  align-self: center;
  margin-bottom: 32px;
}

.dropdown-contact__buttons-wrapper__submit,
.dropdown-contact__buttons-wrapper__cancel {
  border: none;
  font-size: 20px;
  line-height: 26px;
  box-sizing: border-box;
  padding: 14px 16px;
  border-radius: 19px;
  min-height: 56px;
  display: flex;
}

.dropdown-contact__buttons-wrapper__submit::before {
  content: '';
  display: block;
  width: 28px;
  height: 28px;
  background: url('../images/sent_icon.svg') no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.dropdown-contact__buttons-wrapper__submit {
  background:
    linear-gradient(136.37deg, #6C96FF 7.3%, #CAB0FF 94.81%);
  background-blend-mode: hue;
  box-shadow: 0px 10px 26px rgba(108, 150, 255, 0.3);
  color: #FFF;
}

.dropdown-contact__buttons-wrapper__submit:hover:not(:disabled) {
  background:
    linear-gradient(136.37deg, #8C98FF 7.3%, #D6C2FF 94.81%);
  box-shadow: 0px 10px 36px rgba(108, 150, 255, 0.2);
}

.dropdown-contact__buttons-wrapper__submit:active:not(:disabled) {
  background: linear-gradient(136.37deg, #5989FF 7.3%, #BD9DFF 94.81%);
  box-shadow: 0px 4px 18px rgba(108, 150, 255, 0.5);
}

.dropdown-contact__buttons-wrapper__submit:disabled {
  opacity: 0.3;
}

.dropdown-contact__buttons-wrapper__cancel {
  background-color: #E9ECFF;
  color: #6C96FF;
}

.dropdown-contact__buttons-wrapper__cancel::before {
  content: '';
  display: block;
  background: url('../images/sad_icon.svg') no-repeat center;
  background-size: contain;
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.dropdown-contact__buttons-wrapper__cancel:hover {
  background-color: #F4F6FF;
}

.dropdown-contact__buttons-wrapper__cancel:active {
  background-color: #E2E6FF;
}

.dropdown-contact__buttons-wrapper__or {
  display: block;
  margin: 0 32px;
  font-size: 32px;
}

.dropdown-contact__title.mobile {
  display: none;
}

.dropdown-contact__teamlid-message {
  display: none;
}

@media (max-width: 1040px) {
  .dropdown-contact__scroll-wrapper {
    overflow-y: auto;
  }

  .dropdown-contact__title_wrapper {
    display: flex;
    margin: 0 auto 24px;
  }

  .dropdown-contact__title {
    display: inline-flex;
    font-size: calc(12px + 40 * (100vw / 1024));
    line-height: 52px;
  }

  .dropdown-contact__teamlid__photo {
    width: 82px;
    height: 82px;
    object-fit: contain;
    border-radius: 18px;
  }

  .dropdown-contact__form {
    max-width: 70%;
  }

  .dropdown-contact__input {
    padding-bottom: 4px;  
  }
}

@media (max-width: 740px) {
  .dropdown-contact__scroll-wrapper {
    height: 100vh;
  }

  .dropdown-contact {
    padding: 14px 8px 8px;
    position: absolute;
    top: 0;
    background-color: #FBFBFB;
  }
  
  .dropdown-contact__title_wrapper {
    justify-content: space-between;
    align-items: center;
    max-width: 375px;
  }

  .dropdown-contact__form {
    max-width: 375px;
  }

  .dropdown-contact__title.mobile,
  .dropdown-contact__title {
    display: none;
  }

  .dropdown-contact__title__ears {
    font-size: 34px;
    line-height: 44px;
    color: #6764FF;
  }

  .dropdown-contact__title_first {
    position: relative;
    width: 330px;
  }

  .dropdown-contact__title_last {
    width: 220px;
  }

  .dropdown-contact__title.hide {
    display: none;
  }

  .dropdown-contact__teamlid {
    flex-direction: column;
    margin: 0;
    max-width: 80px;
  }

  .dropdown-contact__teamlid__photo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 24px;
    margin-bottom: 8px;
  }

  .dropdown-contact__teamlid__info {
    display: none;
  }
  
  .dropdown-contact__teamlid__info_mobile {
    display: block;
    font-size: 11px;
    line-height: 14px;
    margin: 0;
    width: auto;
    text-align: center;
  }

  .dropdown-contact__teamlid-message {
    display: block;
    box-sizing: border-box;
    padding: 16px 37px 16px 37px;
    align-self: baseline;
    border-radius: 24px;
    background-color: #000;
    color: #FFF;
    font-size: 20px;
    line-height: 24px;
    margin-left: 26px;
    text-align: center;
    min-height: 80px;
    position: relative;
  }

  .dropdown-contact__teamlid-message::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: #000;
    transform: rotate(23deg) skew(-36deg, 14deg);
    left: 0px;
    top: 22px;
    border-radius: 6px;
  }

  .dropdown-contact__buttons-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .dropdown-contact__buttons-wrapper__submit,
  .dropdown-contact__buttons-wrapper__cancel {
    margin-bottom: 16px;
    width: 100%;
    justify-content: center;
  }

  .dropdown-contact__buttons-wrapper__or {
    display: none;
  }

  .notification {
    justify-content: center;
    height: 100vh;
  }

  .notification__img {
    width: 211px;
    height: 199px;
    margin-bottom: 0;
  }

  .notification__title {
    font-size: 50px;
    line-height: 52px;
  }

  .notification__description {
    margin-bottom: 96px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }
}
