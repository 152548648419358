.main-page {
  position: relative;
  box-sizing: border-box;
  padding-top: 174px;
  overflow: hidden;
}

.main-page__pig-image {
  position: absolute;
  right: -200px;
  top: 150px;
  height: 250px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.main-page__pig-image:hover {
  transform: rotate(-15deg);
}

.pig-image-reset-animation {
  animation: resetPigFly 1.5s ease-in-out;
  animation-fill-mode: forwards;
}

.pig-image-fly {
  animation: pigFlyAnimation 2.0s ease-in-out;
  animation-fill-mode: forwards;
}

.main-page__title {
  font-size: 96px;
  line-height: 125px;
  font-weight: normal;
  margin-bottom: 80px;
}

.main-page__title_works {
  margin-bottom: 24px;
  animation-name: titleAnimation;
  will-change: opacity;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes titleAnimation {
  to {
    opacity: 1;
  }
}

.main-page__list-descriptions {
  margin-bottom: 80px;
  list-style: none;
  padding-left: 0;
  min-height: calc(100vh - 174px - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-page__description {
  font-size: 80px;
  line-height: 92px;
  font-weight: 200;
  position: relative;
  box-sizing: border-box;
  padding-left: 48px;
  animation-name: leftAnimation;
  animation-duration: 5s;
  animation-timing-function: cubic-bezier(.17,.67,.34,1.27);
  animation-fill-mode: forwards;
  will-change: opacity;
  opacity: 0;
}

.main-page__description:first-child {
  animation-delay: .2s;
}

.main-page__description:nth-child(2) {
  animation-delay: .4s;
}

.main-page__description:nth-child(3) {
  animation-delay: .6s;
}

.main-page__description:nth-child(4) {
  animation-delay: .8s;
}

.main-page__description::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 30px;
  width: 24px;
  height: 24px;
}

.main-page__description:first-child::before {
  background: url(../images/mark.svg) no-repeat;
  background-position: center center;
  background-size: contain;
}

.main-page__description:nth-child(2)::before {
  background: url(../images/arrowLeft.svg) no-repeat;
  background-position: center center;
  background-size: contain;

}

.main-page__description:nth-child(3)::before {
  background: url(../images/plus.svg) no-repeat;
  background-position: center center;
  background-size: contain;

}

.main-page__description:nth-child(4)::before {
  background: url(../images/diez.svg) no-repeat;
  background-position: center center;
  background-size: contain;

}

.main-page__description:not(:last-child) {
  margin-bottom: 24px;
}

@media (max-width: 1440px) {
  .main-page__description {
    font-size: 70px;
    line-height: 78px;
  }
}

@media (max-width: 1400px) {
  .main-page__description {
    font-size: 52px;
    line-height: 62px;
  }

  .main-page__description::before {
    top: 20px;
  }
}

@media (max-width: 1300px) {

  .main-page__first-showing {
    min-height: inherit;
  }

  .main-page__description {
    position: relative;
    box-sizing: border-box;
    padding-left: 64px;
    font-size: 50px;
    line-height: 62px;
    padding-left: 42px;
  }

  .main-page__description::before {
    top: 20px;
  }
}

@media (max-width: 1024px) {
  .main-page__list-descriptions {
    margin-bottom: 32px;
    min-height: auto;
  }

  .main-page__title_works,
  .main-page__title_works.showed {
    transform: none;
    opacity: 1;
    animation: none;
  }

  .main-page__description {
    animation: none;
    opacity: 1;
  }
}

@media (max-width: 1000px) {
  .main-page__pig-image {
    display: none;
  }
}

@media (max-width: 992px) {
  .main-page__description::before {
    top: 10px;
    width: 12px;
    height: 12px;
    background-size: contain;
  }

  .main-page__description {
    font-size: 24px;
    line-height: 31px;
    font-weight: 300;
    padding-left: 24px;
    animation: none;
    opacity: 1;
  }

  .main-page__title_works,
  .main-page__title_works.showed {
    transform: none;
    opacity: 1;
    font-size: 38px;
    line-height: 49px;
    animation: none;
  }

  .main-page {
    padding-top: 96px;
  }

  .main-page__first-showing {
    height: auto;
  }

  .main-page__description:not(:last-child) {
    margin-bottom: 16px;
  }

  .main-page__works-list {
    opacity: 1;
    transition: 0s ease-in-out;
    transform: translateY(0px);
    pointer-events: all;
  }

  .scroll .main-page__works-list {
    opacity: 1;
    transform: translateY(0);
  }

}

@keyframes leftAnimation {
  from {
  }
  to {
    opacity: 1;
  }
}

@keyframes pigFlyAnimation {
  from {
    right: -130px;
    opacity: 1;
    height: 250px;
  } to {
    right: 1500px;
    opacity: 0;
    height: 0px;
  }
}

@keyframes resetPigFly {
  from {
    right: -200px;
  } to {
    right: -10px;
  }
}
