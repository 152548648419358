.projects-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -28px 32px;
}

.projects-list__project-link {
  display: flex;
  flex-direction: column;
  margin: 0 28px 56px;
  border-radius: 24px;
  color: #FFF;
  box-sizing: border-box;
  position: relative;
  z-index: 50;
  overflow: hidden;
  height: auto;
  width: calc(33.3% - 56px);
  transition: .3s ease-in-out;
}

.projects-list__project-link.in-mine-page:nth-child(1) {
  width: 100%;
}

.projects-list__project-link.in-mine-page:nth-child(2) {
  width: calc(65% - 56px);
  max-height: 600px;
}

.projects-list__project-link.in-mine-page:nth-child(3) {
  width: calc(35% - 56px);
  max-height: 600px;
}

.projects-list__project-link.in-mine-page:nth-child(4),
.projects-list__project-link.in-mine-page:nth-child(5) {
  width: calc(50% - 56px);
  max-height: 600px;
}

/* .projects-list__project-link.mindlimb {
}

.projects-list__project-link.carouselWWF {
} */

.projects-list__project-link.tavi {
  color: #935D73;
}

.projects-list__project-link.yeopen {
  color: #4D4A2F;
}

.projects-list__project-link.themebel {
  color: #4D4A2F;
}

.projects-list__project-link.rosgosstrah {
  color: #4D4A2F;
}

.projects-list__project-link.selecta {
  color: #728E86;
}

.projects-list__project-link.ksitest {
  color: #161616;
}

.project-list__gradient {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}

.projects-list__project-info {
  list-style: none;
  padding-left: 0;
  position: absolute;
  width: calc(100% - 80px);
  bottom: 32px;
  left: 40px;
  right: 40px;
}

.projects-list__item:first-child {
  margin-bottom: 6px;
}

.projects-list__item:nth-child(2) {
  margin-bottom: 24px;
}

.projects-list__project-title {
  font-size: 40px;
  line-height: 52px;
  font-weight: normal;
  margin-bottom: 0;
}

.projects-list__project-link.in-mine-page:first-child .projects-list__project-title {
  /* font-size: 64px; */
}

.projects-list__project-description {
  display: block;
  width: 50%;
  font-size: 20px;
  line-height: 26px;
}

.projects-list__project-tag {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 32px;
  background: #FBFBFB;
  color: #000;
  border-radius: 8px;
  padding: 0 16px;
}

.projects-list__project-link.in-mine-page:first-child .projects-list__project-description {
  /* font-size: 28px;
  line-height: 36px; */
}

.projects-list__project-link.in-mine-page:nth-child(-n+5) .projects-list__project-description {
  max-width: 320px;
}

@media (max-width: 1440px) {
  .projects-list {
    margin: 0 -24px 32px;
  }
  
  .projects-list__project-link {
    margin: 0 12px 24px;
    width: calc(33.3% - 24px);
  }

  .projects-list__project-link.in-mine-page:nth-child(2) {
    width: calc(65% - 24px);
  }
  
  .projects-list__project-link.in-mine-page:nth-child(3) {
    width: calc(35% - 24px);
  }
  
  .projects-list__project-link.in-mine-page:nth-child(4),
  .projects-list__project-link.in-mine-page:nth-child(5) {
    width: calc(50% - 24px);
  }

  .other-projects-list .projects-list__project-description,
  .projects-list__project-link.in-mine-page .projects-list__project-description {
    font-size: calc(12px + 4 * (100vw / 1440) );
    line-height: 20px;
  }
  
  .other-projects-list .projects-list__project-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    margin-bottom: 0;
  }
}

@media (max-width: 1440px) {
  .projects-list__project-info {
    width: calc(100% - 40px);
    left: 20px;
    bottom: 12px;
    right: 20px;
  }
}

@media (max-width: 1279px) {
  .projects-list__project-link.in-mine-page:not(:first-child) {
    width: calc(50% - 24px);
    
  }
  
  .projects-list__project-link {
    width: calc(50% - 24px);
  }
}

@media (max-width: 992px) {
  .projects-list__project-description,
  .projects-list__project-link.in-mine-page:first-child .projects-list__project-description {
    display: block;
    width: 50%;
    font-size: calc(12px + 4 * (100vw / 1440) );
    line-height: 20px;
  }

  .projects-list__project-link {
    height: calc(100vw / 2);
    margin: 0 0px 16px;
  }

  .projects-list__project-link.carouselWWF {
    color: #000;
  }

  .projects-list {
    margin: 0 0 32px;
  }

  .projects-list__project-link.in-mine-page:not(:first-child) {
    width: 100%;
  }

  .projects-list__item:first-child {
    margin-bottom: 0;
  }

  .projects-list__item:nth-child(2) {
    margin-bottom: 16px;
  }

  .projects-list__project-link.in-mine-page:nth-child(-n+5) .projects-list__project-title,
  .projects-list__project-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    margin-bottom: 0;
  }

  .other-projects-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0 -8px;
  }

  .other-projects-list  .projects-list__project-link {
    margin: 0 4px 24px;
    width: calc(84% - 8px);
    height: auto;
    flex-shrink: 0;
  }

  .other-projects-list .projects-list__project-link:first-child {
    margin-left: 8px;
  }

  .other-projects-list .projects-list__project-link:last-child {
    margin-right: 0;
  }

  .other-projects-list .projects-list__project-description {
    max-width: 238px;
  }

  .projects-list__project-link.in-mine-page {
    animation: none;
    opacity: 1;
  }
}

@media (max-width: 660px) {
  .projects-list__project-link.carouselWWF {
    color: #FFF;
  }

  .projects-list__project-link {
    height: calc(100vw - 16px);;
    min-height: 364px;
  }

  .projects-list__project-info {
    left: 24px;
    bottom: 24px;
    right: 24px;
  }

  .other-projects-list .projects-list__project-description {
    max-width: 238px;
  }
}

@keyframes scale-image {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.06);
  }
}
