.main_header__wrapper {
  background-color: #FBFBFB;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 990;
  box-sizing: border-box;
}

.main_header {
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  transition: height .3s ease;
  will-change: height;
}

.main_header.size-header {
  height: 124px;
}

.scroll .main_header.size-header {
  height: 80px;
}

@-moz-document url-prefix() {
  .main_header__wrapper {
    background-color: #FBFBFB;
  }
}

.main_header__logo {
  text-transform: uppercase;
  font-size: 32px;
  padding-right: 30px;
  margin-left: 40px;
  transition: font-size .3s ease;
  will-change: font-size;
}

.main_header__logo.mobile {
  display: none;
}

.size-header .main_header__logo {
  font-size: 56px;
}

.scroll .size-header .main_header__logo {
  font-size: 32px;
}

.main_header__navigation {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.main_header__navigation__link {
  padding-right: 30px;
  font-size: 18px;
  line-height: 23px;
  display: inline-block;
}

.main_header__navigation__link:last-child {
  padding-right: 0;
}

.main_header__navigation__button-contact {
  padding: 14px 16px;
  box-sizing: border-box;
  border: none;
  border-radius: 22px;
  background: linear-gradient(136.37deg, #6C96FF 7.3%, #CAB0FF 94.81%);
  background-blend-mode: hue;
  box-shadow: 0px 10px 26px rgba(108, 150, 255, 0.3);
  color: #FFF;
  font-size: 18px;
  line-height: 23px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-left: 30px;
}

.main_header__navigation__button-contact::before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: url(../../images/smile-icon.svg) no-repeat;
  background-size: contain;
  margin-right: 10px;
  transition: .3s ease-in-out;
}

.main_header__navigation__button-contact:hover {
  background: linear-gradient(136.37deg, #8C98FF 7.3%, #D6C2FF 94.81%);
  box-shadow: 0px 10px 36px rgba(108, 150, 255, 0.2);
  background-size: 100%;
}

.main_header__navigation__button-contact:active {
  background: linear-gradient(136.37deg, #5989FF 7.3%, #BD9DFF 94.81%);
  box-shadow: 0px 4px 18px rgba(108, 150, 255, 0.5);
  background-size: 100%;
}

.main_header__navigation__button-contact:disabled {
  background: linear-gradient(136.37deg, rgba(108, 150, 255, 0.2) 7.3%, rgba(202, 176, 255, 0.2) 94.81%);
  box-shadow: 0px 10px 26px rgba(108, 150, 255, 0.3);
}

.scroll .main_header__navigation__button-contact:hover,
.scroll .main_header__navigation__button-contact:active {
  background-size: 100%;
}

.hamburger-button {
  display: none;
}

@media (max-width: 1279px) {
  .main_header__logo,
  .size-header .main_header__logo,
  .scroll .size-header .main_header__logo {
    font-size: 40px;
  }
}

@media (max-width: 1000px) {
  .main_header,
  .main_header.size-header,
  .scroll .main_header.size-header {
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 8px;
    min-height: 64px;
    height: auto;
    border-bottom: 1px solid #EDEDED;
  }

  .main_header__logo,
  .size-header .main_header__logo,
  .scroll .size-header .main_header__logo {
    display: none;
    margin-left: 0;
    font-size: 22px;
    line-height: 28px;
    word-wrap: none;
    width: 121px;
    flex-grow: 0;
    flex-shrink: 0;
    transform: none;
  }

  .main_header__logo.mobile,
  .size-header .main_header__logo.mobile,
  .scroll .size-header .main_header__logo.mobile {
    display: block;
  }

  .main_header__navigation__button-contact,
  .scroll .main_header__navigation__button-contact {
    font-size: 16px;
    line-height: 0px;
    padding: 12px;
    margin-right: 16px;
    margin-left: auto;
    border-radius: 16px;
  }

  .main_header__navigation__button-contact::before {
    width: 28px;
    height: 28px;
  }

  .hamburger-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 3;
    width: 52px;
    height: 52px;
    background-color: #FFF;
    border-radius: 16px;
    box-shadow: 0px 6px 26px rgba(183, 200, 213, 0.3);
    border: none;
    flex-shrink: 0;
  }

  .hamburger-button__item_top,
  .hamburger-button__item_mid,
  .hamburger-button__item_bottom {
    width: 20px;
    border: 1px solid #000;
    border-radius: 1px;
    transform-origin: center;
    transition: .2s linear;
  }

  .hamburger-button__item_top,
  .hamburger-button__item_mid {
    margin-bottom: 4px;
  }

  .hamburger-button.show .hamburger-button__item_top {
    transform: translateY(3px) rotate(45deg);
    margin-bottom: -2px;
  }

  .hamburger-button.show .hamburger-button__item_bottom {
    transform:  translateY(-3px) rotate(135deg);
    margin-bottom: 0;
  }

  .hamburger-button.show  .hamburger-button__item_mid  {
    transform: rotate(180deg);
    opacity: 0;
  }

  .main_header__navigation {
    width: 100%;
    height: 0;
    margin-left: 0;
    display: block;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-40px) scaleY(-100%);
    transition: .3s ease-in-out;
    box-sizing: border-box;
    padding: 0px;
    order: 4;
  }

  .hamburger-button.show + .main_header__navigation {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px) scaleY(1);
    padding-top: 8px;
    height: 100vh;
  }

  .main_header__navigation__link {
    display: block;
    box-sizing: border-box;
    border-bottom: 1px solid #EDEDED;
    font-size: 28px;
    line-height: 37px;
    padding: 16px 0;
  }

  .scroll .main_header__navigation__link {
    font-size: 28px;
    line-height: 37px;
  }
}

@media (max-width: 740px) {
  .main_header.hide-header {
    display: none;
  }
}

@media (max-width: 410px) {

  .main_header__navigation__button-contact,
  .scroll .size-header .main_header__navigation__button-contact,
  .scroll .main_header__navigation__button-contact,
  .size-header .main_header__navigation__button-contact {
    font-size: 0px;
  }

  .main_header__navigation__button-contact::before {
    margin-right: 0;
  }
}
